$app-body-color: #ecf5fd;
// $my-primary-color: #1b2838;
$my-primary-color: rgb(44, 64, 90);
$my-secondary-color: rgb(110, 192, 187);

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  // background-color: $app-body-color !important;
  background-color: rgb(243, 243, 243) !important;
  margin: 0;
  color: $my-primary-color;
  overflow: -webkit-paged-y;
  //overflow-y: hidden;
  height: 100vh;
}

// Hide scrollbar Chrome, Safari, Opera
body::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar Firefox
html {
  scrollbar-width: none;
}

.btn-primary {
  background-color: $my-primary-color;
  border-color: $my-primary-color;
}

.page-item.active .page-link {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: $my-primary-color !important;
}

.page-link {
  color: $my-primary-color;
}

.nav-link {
  color: $my-primary-color;
}

.dropdown-toggle::after {
  display: none;
}

select.form-control {
  width: 10rem;
}

.form-group {
  margin: 5px 0 5px 0;
}

span.page-link:hover {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

tspan {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

li.filter-title span {
  color: #2c405a !important;
  font-weight: 700;
  min-width: 6rem;
  margin-right: 0.25rem;
}

.react-datepicker__input-container input {
  color: #6c757d;
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid gray;
  padding: 0.25rem 0.5rem;
  margin-top: -3px;
  margin-right: 0.45rem;
}

.form-control {
  display: inline !important;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
}

a {
  color: rgb(44, 64, 90);
  text-decoration: none; /* no underline */
}

a:hover {
  text-decoration: none;
}

.input-mobile {
  margin-bottom: 0.5rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background-color: white;
  display: block !important;
}

// label {
//   display: block !important;
// }

@media only screen and (max-width: 1000px) {
  body {
    background-color: white !important;
  }
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

input[type='text'],
textarea {
  outline: none !important;
  box-shadow: none !important;
}

select {
  outline: none !important;
  box-shadow: none !important;
}

th.position-relative {
  cursor: pointer;
}

.highchdarts-root {
  max-width: 96%;
  display: inline-block;
}

.pass-reg-row {
  max-width: 80% !important;
  display: flex;
  align-items: center;
}

.datepicker-credit-investor {
  margin: 0 !important;
  padding: 0 !important;
}

.modal-header,
.modal-footer {
  background-color: #fafbfc !important;
}

.btn-link {
  text-decoration: none !important;
}

input,
select:not(.round-blue) {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.react-datepicker__input-container input {
  max-width: 100px !important;
}
